<template>
  <b-card no-body>

    <div>

      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-around">

          <!-- Search -->
          <b-col
            cols="12"
            md="10"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input

                class="d-inline-block mr-1"
                placeholder="Szukaj..."
              />

            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="d-flex justify-content-end"
          >
            <b-button
              v-b-modal.modal-lg
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </b-col>

        </b-row>
      </div>

      <!-- Main table element -->
      <b-table
        :items="items"
        :fields="fields"
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
        </template>

        <template #cell(actions)="row">

          <b-button
            size="sm"
            variant="primary"
            @click="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Schowaj' : 'Pokaż' }}
          </b-button>
        </template>

        <template #row-details="row">

          <b-row
            v-for="n in 12"
            v-bin:key="n"
            class="payroll-list--item"
          >

            <b-col>
              <b-link :to="{ name: 'single-payroll', params: { id: '123' }}">
                lista płac {{ n }}/2022
              </b-link>
            </b-col>

          </b-row>

        </template>
      </b-table>

    </div>

    <b-modal
      id="modal-lg"
      ok-only
      ok-title="Zapisz"
      centered
      size="lg"
      title="Dostosuj wyświetlanie kolumn"
    >

      <div class="d-flex flex-column">

        <b-card-text class="mt-2 mb-2">
          Wybierz kolumny , które mają być wyświetlane na liście
        </b-card-text>

        <div class="mt-1">

          <b-form-checkbox
            id="5"
            :checked="true"
            name="check-button"
            switch
            inline
          >
            <span>ID</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="6"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>Company name</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="7"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>NIP</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="8"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>Email</span>
          </b-form-checkbox>

        </div>

        <div class="mt-1">

          <b-form-checkbox
            id="1"
            :checked="true"
            name="check-button"
            switch
            inline
          >
            <span>Country</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="2"
            :checked="false"
            name="check-button"
            switch
            inline
          >
            <span>Created</span>
          </b-form-checkbox>

        </div>

      </div>

    </b-modal>

  </b-card>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BFormCheckbox, BTooltip, BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BTooltip,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      items: [
        { isActive: true, age: 40, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: false, age: 21, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: false, age: 89, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: true, age: 38, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: false, age: 27, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: true, age: 40, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: false, age: 26, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: false, age: 22, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: true, age: 38, name: { first: 'Jan', last: 'Nowak' } },
        { isActive: false, age: 29, name: { first: 'Jan', last: 'Nowak' } },
      ],
      fields: [
        {
          key: 'name', label: 'Imię i nazwisko', sortable: true, sortDirection: 'desc',
        },

        { key: 'actions', label: 'Listy płac' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.payroll-list--item {
   background-color: #f3f2f7;
   border-bottom: 2px solid #ebe9f1;
    padding: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
